import { render, staticRenderFns } from "./ClientInterview.vue?vue&type=template&id=525cb1c2&scoped=true&"
import script from "./ClientInterview.vue?vue&type=script&lang=js&"
export * from "./ClientInterview.vue?vue&type=script&lang=js&"
import style0 from "./ClientInterview.vue?vue&type=style&index=0&id=525cb1c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525cb1c2",
  null
  
)

export default component.exports
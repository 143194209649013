<template>
  <section>
    <div>
      <image-section tag="article" :section-title="$t(sectEnginePerformance)"
                     :contents-sub-title="$t(dataDescTable.title)"
                     contents-sub-title-transform="capitalize"
                     mode="row">
        <template #image>
          <v-row>
            <v-col>
              <v-img :src="dataDescTable.image.horizontal"
                     :lazy-src="dataDescTable.image.horizontal"
                     data-aos="fade-down"
                     :alt="dataDescTable.title"
                     :aspect-ratio="1180/325"
                     v-if="$vuetify.breakpoint.smAndUp">
                <template #placeholder>
                  <image-place-holder/>
                </template>
              </v-img>
              <v-img :src="dataDescTable.image.vertical"
                     :lazy-src="dataDescTable.image.vertical"
                     :alt="dataDescTable.title"
                     :aspect-ratio="480/720"
                     data-aos="fade-down"
                     v-else>
                <template #placeholder>
                  <image-place-holder/>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </template>
      </image-section>
    </div>

    <div>
      <image-section tag="article"
                     :contents-sub-title="$t(comparisonResults.title)"
                     contents-sub-title-transform="capitalize"
                     mode="row">
        <template #image>
          <v-row>
            <v-col cols="12" sm="4" v-for="(chart, idx) in comparisonResults.charts" :key="idx">
              <v-img :src="chart.image" :lazy-src="chart.image"
                     :alt="chart.alt"
                     :aspect-ratio="380/385"
                     data-aos="zoom-in" :data-aos-delay="idx*150">
                <template #placeholder>
                  <image-place-holder/>
                </template>
              </v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-img :src="comparisonResults.tables.horizontal"
                     :lazy-src="comparisonResults.tables.horizontal"
                     data-aos="fade-down"
                     :alt="comparisonResults.title"
                     :aspect-ratio="1187/216"
                     v-if="$vuetify.breakpoint.smAndUp">
                <template #placeholder>
                  <image-place-holder/>
                </template>
              </v-img>
              <v-img :src="comparisonResults.tables.vertical"
                     :lazy-src="comparisonResults.tables.vertical"
                     :alt="comparisonResults.title"
                     :aspect-ratio="480/486"
                     data-aos="fade-down"
                     v-else>
                <template #placeholder>
                  <image-place-holder/>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </template>
      </image-section>
    </div>
  </section>
</template>

<script>
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import ImagePlaceHolder from '@/components/ImagePlaceHolder';

export default {
  name: 'SectionEnginePerformance',
  components: {
    ImageSection,
    ImagePlaceHolder,
  },
  data: () => ({
    sectEnginePerformance: 'performanceSect1Title',
    dataDescTable: {
      title: 'dataDescTitle',
      image: {
        horizontal: require('@/assets/images/performance/data-description.svg'),
        vertical: require('@/assets/images/performance/data-description-mobile.svg'),
      },
    },
    comparisonResults: {
      title: 'performComparisonTitle',
      charts: [
        {
          alt: 'recommendation performance: comparison by joara reading data',
          image: require('@/assets/images/performance/comparison-joara.svg'),
        },
        {
          alt: 'recommendation performance: comparison by tving watching data',
          image: require('@/assets/images/performance/comparison-tiving.svg'),
        },
        {
          alt: 'recommendation performance: comparison by movielens rating data',
          image: require('@/assets/images/performance/comparison-movielens.svg'),
        },
      ],
      tables: {
        horizontal: require('@/assets/images/performance/performance-comparison.svg'),
        vertical: require('@/assets/images/performance/performance-comparison-mobile.svg'),
      },
      image: require('@/assets/images/performance/data-description.svg'),
    },
  }),
};
</script>
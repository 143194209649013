<template>
  <section>
    <section-wrap prop-class="text-center pb-0">
      <div class="text-t2">
        {{ $t(sectionTitle) }}
      </div>
    </section-wrap>
    <page-header-sub
        :sub-title="$t(sectionHeader.subTitle)"
        :title="$t(sectionHeader.headLine)"
        :description="$t(sectionHeader.description)"
        :image="sectionHeader.image"/>
    <v-container>
      <v-row align="center">
        <v-col class="flex-grow-0" cols="12" sm="auto">
          <div class="pa-3">
            <v-img :src="introduceClient.logo"
                   :lazy-src="introduceClient.logo"
                   :alt="introduceClient.clientName"
                   :width="$vuetify.breakpoint.xsOnly ? 80 :210" aspect-ratio="1" contain
                   data-aos="zoom-in"></v-img>
          </div>
        </v-col>
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        <v-col class="flex-grow-1">
          <div class="text-t4 font-weight-bold mb-3" data-aos="fade-right">
            {{ $t(introduceClient.title) }}
          </div>
          <div class="text-t5 paragraph--text" data-aos="fade-right">
            <p v-for="(paragraph, idx) in introduceClient.paragraphs" :key="idx">
              {{ $t(paragraph) }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <section-wrap prop-class="pb-0">
      <div class="text-t4 font-weight-bold">{{ $t(expectedEffectsTitle) }}</div>
    </section-wrap>
    <section-wrap prop-class="pt-8">
      <ul class="unordered-bullet-list text-t4">
        <li v-for="effect in expectedEffects">{{ $t(effect) }}</li>
      </ul>
    </section-wrap>

    <section-wrap prop-class="pb-0">
      <div class="text-t4 font-weight-bold">{{ $t(solutions.title) }}</div>
    </section-wrap>
    <image-section tag="article"
                   :contents-title="$t(content.title)"
                   :description="$t(content.description)"
                   :image="content.image"
                   :reverse="idx%2 === 0"
                   v-for="(content, idx) in solutions.contents" :key="idx">
    </image-section>

    <section-wrap prop-class="pb-0">
      <div class="text-t4 font-weight-bold">{{ $t(resultsTitle) }}</div>
    </section-wrap>
    <image-section tag="article"
                   :contents-title="$t(resultMain.title)"
                   :description="$t(resultMain.description)"
                   :image="resultMain.chart"
                   align-item="start">
    </image-section>
    <section-wrap class="py-0">
      <div class="text-t3 font-weight-bold">{{ $t(keyBusinessIndicators.title) }}</div>
      <v-row class="pt-5" justify="space-between">
        <v-col cols="12" sm="4" lg="3" class="d-flex flex-column justify-space-between"
               v-for="(indicator, idx) in keyBusinessIndicators.indicators" :key="idx">
          <div class="text-t5 font-weight-bold mb-5">
            {{ $t(indicator.label) }}
          </div>
          <div class="d-flex paragraph--text key-value-display"
               :lang="$i18n.locale.split('-')[0]">
            <div class="text-t6 key-value-display__sup">more than</div>
            <div class="impact-number primary--text">
              {{ indicator.value }}
            </div>
            <div class="text-t4 font-weight-bold d-flex align-end pb-5 key-value-display__sub">%
            </div>
          </div>
        </v-col>
      </v-row>
    </section-wrap>

    <!--<section-wrap tag="article">-->
    <!--  <v-row>-->
    <!--    <v-col>-->
    <!--      <client-interview/>-->
    <!--    </v-col>-->
    <!--  </v-row>-->
    <!--</section-wrap>-->
    <section-wrap tag="article">
      <v-row>
        <v-col>
          <div class="text-t3 font-weight-bold">{{ $t(userMonitoringResults.title) }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-t5 paragraph--text">{{ $t(userMonitoringResults.description) }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-img :src="userMonitoringResults.image"
                 :lazy-src="userMonitoringResults.image"
                 :aspect-ratio="1180/2827" contain>
            <template #placeholder>
              <image-place-holder/>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </section-wrap>
  </section>
</template>

<script>
import PageHeaderMain from '@/layouts/ServiceIntro/Sections/PageHeaderMain';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';
import PageHeaderSub from '@/layouts/ServiceIntro/Sections/PageHeaderSub';

import SectionWrap from '@/layouts/ServiceIntro/Sections/SectionWrap';
import ImagePlaceHolder from '@/components/ImagePlaceHolder';
import ClientInterview from '@/components/ClientInterview';

export default {
  name: 'SectionBizPerformance',
  components: {
    PageHeaderSub,
    PageHeaderMain,
    ImageSection,
    SectionWrap,
    ImagePlaceHolder,
    ClientInterview,
  },
  data: () => ({
    sectionTitle: 'performanceSect2Title',
    sectionHeader: {
      subTitle: 'successStory',
      headLine: 'joaraStoryHeadline',
      description: 'joaraStoryDesc',
      image: require('@/assets/images/performance/introduce-joara-story@2x.png'),
    },

    introduceClient: {
      clientName: 'Joara',
      logo: require('@/assets/images/performance/logo-joara@2x.png'),

      title: 'aboutJoaraTitle',
      paragraphs: [
        'aboutJoaraBodyPar1',
        'aboutJoaraBodyPar2',
        'aboutJoaraBodyPar3',
      ],
    },

    expectedEffectsTitle: 'joaraBizBenfitPartTitle',
    expectedEffects: [
      'joaraBizBenefit1',
      'joaraBizBenefit2',
      'joaraBizBenefit3',
      'joaraBizBenefit4',
    ],

    solutions: {
      title: 'Solutions',
      contents: [
        {
          title: 'joaraSolutionScreen1Title',
          description: 'joaraSolutionScreen1Desc',
          image: require('@/assets/images/performance/joara/ex-screen-01-homepage@2x.png'),
        },
        {
          title: 'joaraSolutionScreen2Title',
          description: 'joaraSolutionScreen2Desc',
          image: require(
              '@/assets/images/performance/joara/ex-screen-02-recommendation page@2x.png'),
        },
        {
          title: 'joaraSolutionScreen3Title',
          description: 'joaraSolutionScreen3Desc',
          image: require('@/assets/images/performance/joara/ex-screen-03-detail page@2x.png'),
        },
        {
          title: 'joaraSolutionScreen4Title',
          description: 'joaraSolutionScreen4Desc',
          image: require('@/assets/images/performance/joara/ex-screen-04-end of story@2x.png'),
        },
      ],
    },

    resultsTitle: 'results',
    resultMain: {
      title: 'joaraBizResultsHeadline',
      description: 'joaraBizResultsDesc',
      chart: require('@/assets/images/performance/increase-ctr-in-joara.svg'),
    },

    keyBusinessIndicators: {
      title: 'joaraBizKeyPartTitle',
      indicators: [
        {
          label: 'joaraBizKeyPart1Head',
          value: 13,
        },
        {
          label: 'joaraBizKeyPart2Head',
          value: 62,
        },
        {
          label: 'joaraBizKeyPart3Head',
          value: 58,
        },
      ],
    },

    userMonitoringResults: {
      title: 'joaraUserReactionTitle',
      description: 'joaraUserReactionDesc',
      image: require('@/assets/images/performance/joara/voc_section@2x.png'),
    },
  }),
};
</script>

<style lang="scss" scoped>
.unordered-bullet-list {
  list-style: none;
  padding: 0;

  li {
    padding: 5px 0px 5px 1.5em;
    position: relative;

    &:before {
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro';

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;

      color: var(--v-primary-base, #4c9892);
    }
  }
}

.key-value-display {
  &[lang=ko] &__sup {
    display: none;
  }

  &[lang=ko] &__sub {
    &:after {
      margin-left: 0.5em;
      content: ' 이상';
    }
  }

  .impact-number {
    font-size: 150px;
    font-weight: 700;
    line-height: 1;

    @media (max-width: 900px) and (min-width: 600px) {
      font-size: 7em;
    }
  }
}
</style>
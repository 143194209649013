<template>
  <article>
    <div class="interview-box primary white--text text-center pa-3 pa-lg-8">
      <div class="interview-box__content py-5">
        <div class="interview-box__comment text-t2">
          <sup><i class="fas fa-quote-left"></i></sup>
          Comment context!
          <sub><i class="fas fa-quote-right"></i></sub>
        </div>

        <div class="text-t5 font-weight-bold pb-5">Kim Leo</div>
        <div class="text-t6">Braincolla.Inc CEO & Founder</div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ClientInterview',
}
</script>

<style lang="scss" scoped>
.interview-box {
  border-radius: 22px;

  &__comment {
    min-height: 3em;
    padding-bottom: 32px;

    sup, sub {
      opacity: 0.3;
    }
  }
}
</style>

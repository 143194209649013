<template>
  <div>
    <page-header-main
        :sub-title="$t(header.subTitle)"
        :title="$t(header.title)"
        :description="$t(header.description)"
        :image-aspect-ratio="1180/350"
        :image="header.image">
    </page-header-main>

    <section-engine-performance/>

    <section-biz-performance/>
  </div>
</template>

<script>
import PageHeaderMain from '@/layouts/ServiceIntro/Sections/PageHeaderMain';

import SectionEnginePerformance from './SectionEnginePerformance';
import SectionBizPerformance from './SectionBizPerformance';

export default {
  name: 'PerformanceView',
  components: {
    PageHeaderMain,
    SectionEnginePerformance,
    SectionBizPerformance,
  },
  data: () => ({
    header: {
      subTitle: 'Performance',
      title: 'performanceTitle',
      description: 'performanceDesc',
      image: require('@/assets/images/performance/main-visual@2x.png'),
    },
  }),
};
</script>

